import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/edit/actionTypes';

import { toggleAdditional } from './utils';

export default function* listenToggleAdditional() {
  while (true) {
    const { payload } = yield take(actionTypes.TOGGLE_ADDITIONAL);
    yield toggleAdditional(payload);
  }
}
