export const parse = valueRaw => valueRaw ? ({
  id: valueRaw._id,
  name: valueRaw.name,
  shortName: valueRaw.shortName,
  color: valueRaw.color,
  customAction: valueRaw.customAction,
}) : null;

export const serialize = value => ({
  _id: value.id,
  name: value.name,
  shortName: value.shortName,
  color: value.color,
  customAction: value.customAction,
});
