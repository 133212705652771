import { put, take, call } from 'redux-saga/effects';

import * as customerActions from '../../store/modules/customer/actions';
import * as actionTypes from '../../store/modules/view/actionTypes';

import { showError } from '../../store/modules/app/actions';

import api from '../../services/apiService';

function* selectCustomerSuggestion(payload) {
  try {
    const customer = yield call(api.customer.getCustomer, { id: payload.id });

    yield put(customerActions.setCustomer(customer));
    yield put(customerActions.setVisibility(true));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    // yield put(actions.setLoading(false));
  }
}

export default function* listenSelectCustomerSuggestion() {
  while (true) {
    const { payload } = yield take(actionTypes.SELECT_CUSTOMER_SUGGESTION);
    yield selectCustomerSuggestion(payload);
  }
}
