import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/view/actionTypes';

import { loadBookings } from './utils';

export default function* listenLoadBookings() {
  while (true) {
    yield take(actionTypes.LOAD_BOOKINGS);
    yield loadBookings();
  }
}
