import React, { Component } from 'react';
import { connect } from 'react-redux';

import Fade from '@material-ui/core/Fade';

import Header from './Header';
import EditForm from './EditForm';
import BookingsListView from './BookingsListView';
import Preview from './Preview';
import CustomerForm from './CustomerForm';

import { initView } from '../../store/modules/view/actions';

type Props = {
  onPageOpen: () => void,
}

class MainPage extends Component<Props> {
  componentDidMount() {
    const { onPageOpen } = this.props;
    onPageOpen();
  }

  render() {
    return (
      <Fade timeout={500} in>
        <div>
          <Header />
          <BookingsListView />
          <EditForm />
          <CustomerForm />
          <Preview />
        </div>
      </Fade>
    );
  }
}

const mapActions = {
  onPageOpen: initView,
};

export default connect(null, mapActions)(MainPage);
