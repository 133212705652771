import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import TextInput from '../../common/components/TextInput';

import { authorize, setFieldValue } from '../../store/modules/user/actions';

import { isNullOrWhiteSpace } from '../../utils/helpers/stringUtils';

type Props = {
  login: string,
  password: string,
  loading: boolean,
  onChangeField: (name: string, value: string) => void,
  onAuthorize: () => void,
  classes: mixed,
}

class AuthPage extends Component<Props> {
  render() {
    const {
      login,
      password,
      onChangeField,
      onAuthorize,
      loading,
      classes,
    } = this.props;

    const authButtonDisabled = (isNullOrWhiteSpace(login) || isNullOrWhiteSpace(password))
     || loading;

    return (
      <Fade timeout={500} in>
        <div className={classes.pageContainer}>
          <Card className={classes.formContainer}>
            <CardContent>
              <div className={classes.title}>{process.env.REACT_APP_ENV === 'staging' ? 'ДЕМОВЕРСИЯ' : '' }</div>
              <TextInput
                autoFocus
                value={login}
                placeholder="Логин"
                onChange={value => onChangeField('login', value)}
              />
              <TextInput
                value={password}
                type="password"
                placeholder="Пароль"
                onChange={value => onChangeField('password', value)}
              />
            </CardContent>
            <CardActions className={classes.actionsContainer}>
              <Button
                className={classes.button}
                disabled={authButtonDisabled}
                variant="contained"
                color="primary"
                onClick={onAuthorize}
              >
                Войти
              </Button>
            </CardActions>
            <Fade in={loading}>
              <LinearProgress className={classes.loader} />
            </Fade>
          </Card>
        </div>
      </Fade>

    );
  }
}

const styles = {
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#eeeeee',
    alignItems: 'center',
    height: '100vh',
    margin: 0,
  },
  formContainer: {
    width: '350px',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
  }
};

const mapStateToProps = ({ user: { auth } }) => ({
  login: auth.login,
  password: auth.password,
  loading: auth.loading,
});

const mapActions = {
  onAuthorize: authorize,
  onChangeField: setFieldValue,
};

export default compose(
  connect(mapStateToProps, mapActions),
  withStyles(styles),
)(AuthPage);
