const prefix = 'view';

export const INIT_VIEW = `${prefix}/INIT_VIEW`;

export const SET_LOADING = `${prefix}/SET_LOADING`;
export const SET_BOOKINGS = `${prefix}/SET_BOOKINGS`;
export const SET_SELECTED_DATE = `${prefix}/SET_SELECTED_DATE`;
export const SET_SEARCH_STRING = `${prefix}/SET_SEARCH_STRING`;
export const GET_CUSTOMER_SUGGESTIONS = `${prefix}/GET_CUSTOMER_SUGGESTIONS`;
export const SET_CUSTOMER_SUGGESTIONS = `${prefix}/SET_CUSTOMER_SUGGESTIONS`;
export const SELECT_CUSTOMER_SUGGESTION = `${prefix}/SELECT_CUSTOMER_SUGGESTION`;
export const CLEAR_SEARCH = `${prefix}/CLEAR_SEARCH`;
export const CREATE_BOOKINGS_BY_TEMPLATE = `${prefix}/CREATE_BOOKINGS_BY_TEMPLATE`

export const SELECT_BOOKING = `${prefix}/SELECT_BOOKING`;
export const EDIT_BOOKING = `${prefix}/EDIT_BOOKING`;
export const CREATE_BOOKING = `${prefix}/CREATE_BOOKING`;
export const MOVE_BOOKING = `${prefix}/MOVE_BOOKING`;

export const ADD_BOOKING = `${prefix}/ADD_BOOKING`;
export const UPDATE_BOOKING = `${prefix}/UPDATE_BOOKING`;
export const DELETE_BOOKING = `${prefix}/DELETE_BOOKING`;

export const LOAD_BOOKINGS = `${prefix}/LOAD_BOOKING`;
export const SET_BOOKING_DIRTY = `${prefix}/SET_BOOKING_DIRTY`;
export const SET_BOOKING_LIST_DIRTY = `${prefix}/SET_BOOKING_LIST_DIRTY`;
export const NEW_BOOKING_CREATED = `${prefix}/NEW_BOOKING_CREATED`;
