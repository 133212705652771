import { combineReducers } from 'redux';

import app from './modules/app/reducer';
import user from './modules/user/reducer';
import view from './modules/view/reducer';
import edit from './modules/edit/reducer';
import customer from './modules/customer/reducer';

export default combineReducers({
  view,
  edit,
  app,
  user,
  customerForm: customer,
});
