import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/user/actionTypes';
import { authorize } from './utils';

export default function* listenAuthorize() {
  while (true) {
    yield take(actionTypes.AUTHORIZE);
    yield authorize();
  }
}
