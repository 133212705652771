export const lightGray = '#A2A2A2';

export const fontItemColor = 'white';

export const unconfirmedItemColor = '#FBC02D';
export const confirmedItemColor = '#4CAF50';
export const checkedInItemColor = '#2B98F0';
export const endingSoonItemColor = '#b71c1c';
export const checkedOutItemColor = '#2c387e';
export const onlineItemColor = '#ab9682';
export const lockedItemColor = '#a2a2a2'

export const selectedItemColor = '#f50057';
export const defaultItemColor = '#2B98F0';
