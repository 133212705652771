export default {
  currentBooking: null,
  customerSuggestions: [],
  loading: false,
  visibility: {
    preview: false,
    full: false,
    delete: false,
  },
};
