import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        currentBooking: {
          ...state.currentBooking,
          [payload.fieldName]: payload.value,
        },
      };

    case actionTypes.SET_VISIBILITY:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          ...payload,
        },
      };

    case actionTypes.SET_CURRENT_BOOKING:
      return { ...state, currentBooking: payload };

    case actionTypes.SET_LOADING:
      return { ...state, loading: payload };

    case actionTypes.CANCEL:
      return { ...state,
        visibility: {
          full: false,
          delete: false,
          preview: payload,
        } };

    case actionTypes.SET_CUSTOMER:
      return {
        ...state,
        customer: payload,
        currentBooking: {
          ...state.currentBooking,
          phone: payload.phoneNumber,
          name: payload.name,
          surname: payload.surname,
          email: payload.email,
          preferences: payload.preferences,
          description: payload.comment,
          customerId: payload.id,
        },
      };

    case actionTypes.SET_CUSTOMER_SUGGESTIONS:
      return {
        ...state,
        customerSuggestions: payload,
      };

    case actionTypes.CHANGE_PHONE:
      return {
        ...state,
        currentBooking: {
          ...state.currentBooking,
          phone: payload,
          customerId: null,
        },
      };

    case actionTypes.TOGGLE_ONLINE:
      return {
        ...state,
        currentBooking: {
          ...state.currentBooking,
          isOnline: payload,
        },
      };

    default: return state;
  }
};
