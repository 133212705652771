let apiToken;

export const saveToken = (tokenValue) => {
  apiToken = tokenValue;
  sessionStorage.setItem('HostessApiToken', tokenValue);
};

export const getToken = () => {
  return apiToken || sessionStorage.getItem('HostessApiToken');
};

export const removeToken = () => {
  apiToken = undefined;
  sessionStorage.removeItem('HostessApiToken');
};
