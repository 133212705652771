import makeRequest from '../utils/makeRequest';
import errorService from '../../errorService';
import moment from 'moment';

import * as bookingConverter from '../../../common/models/booking';
import * as dateConverter from '../../../common/models/date';

const baseBookingUrl = 'bookings';

export const getBookings = async ({ from, to }) => makeRequest({
  method: 'get',
  url: baseBookingUrl,
  params: { from: dateConverter.serialize(from), to: dateConverter.serialize(to) },
  errorWrapper: errorService.booking.loadingErr,
}).then(r => r.map(bookingConverter.parse));

export const updateBooking = async ({ booking }) => makeRequest({
  method: 'put',
  url: `${baseBookingUrl}/${booking.id}`,
  body: bookingConverter.serialize(booking),
  errorWrapper: errorService.booking.updatingErr,
}).then(bookingConverter.parse);

export const createBooking = async ({ booking }) => makeRequest({
  method: 'post',
  url: baseBookingUrl,
  body: bookingConverter.serialize(booking),
  errorWrapper: errorService.booking.creatingErr,
}).then(bookingConverter.parse);

export const deleteBooking = async ({ bookingId }) => makeRequest({
  method: 'delete',
  url: `${baseBookingUrl}/${bookingId}`,
  errorWrapper: errorService.booking.deletingErr,
});

export const createBookingsByTemplate = async ({ currentDate }) => makeRequest({
  method: 'post',
  url: `${baseBookingUrl}/template`,
  body: { date: moment(currentDate).toLocaleString() },
  errorWrapper: errorService.booking.creatingErr,
});
