import { put, take, select } from 'redux-saga/effects';
import * as actionTypes from '../../store/modules/edit/actionTypes';
import * as editActions from '../../store/modules/edit/actions';
import { showError } from '../../store/modules/app/actions';

function* clearPhone() {
  try {
      yield put(editActions.setFieldValue('phone', ''));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(editActions.setLoading(false));
  }
}

export default function* listenClearPhone() {
  while (true) {
    yield take(actionTypes.CLEAR_PHONE);
    yield clearPhone();
  }
}
