import { io } from 'socket.io-client';

const ioSocket = io(process.env.REACT_APP_SOCKET_URL);

function subscribeToBookingUpdate(handler) {
  ioSocket.on('bookingUpdated', bookingId => handler(bookingId));
}

function subscribeToBookingCreate(handler) {
  ioSocket.on('bookingCreated', startTimeUtc => handler(startTimeUtc));
}

export { subscribeToBookingUpdate, subscribeToBookingCreate };