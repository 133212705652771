import moment from 'moment';
import { take, select, put } from 'redux-saga/effects';
import * as actionTypes from '../../store/modules/view/actionTypes';
import * as viewActions from '../../store/modules/view/actions';
import { setBookingListDirty } from './utils';

const getSelectedDate = state => state.view.selectedDate;

export default function* listenNewBookingCreated() {
  while (true) {
    const { payload } = yield take(actionTypes.NEW_BOOKING_CREATED);
    const selectedDate = yield select(getSelectedDate)
    if (moment(payload).format('DD.MM.YYYY') === moment(selectedDate).format('DD.MM.YYYY')) {
      yield put(viewActions.setBookingListDirty(true));
    }
  }
}
