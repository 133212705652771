// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import saga from '../saga';

import rootReducer from './rootReducer';

let middlewareEnhancer = applyMiddleware(saga);

if (process.env.REACT_APP_ENV !== 'production') {
  middlewareEnhancer = composeWithDevTools(middlewareEnhancer);
}

const store = createStore(rootReducer, middlewareEnhancer);

export default store;
