import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/user/actionTypes';
import { loadUserSettings } from './utils';

export default function* listenLoadUserSettings() {
  while (true) {
    yield take(actionTypes.LOAD_USER_SETTINGS);
    yield loadUserSettings();
  }
}
