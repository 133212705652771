import { call, take, put, select } from 'redux-saga/effects';
import { showError } from '../../store/modules/app/actions';

import * as actionTypes from '../../store/modules/customer/actionTypes';
import * as actions from '../../store/modules/customer/actions';

import api from '../../services/apiService';

const getCustomer = state => state.customerForm.customer;

export function* saveCustomer() {
  try {
    yield put(actions.setLoading(true));

    const customer = yield select(getCustomer);

    if (customer.id) {
      yield call(api.customer.updateCustomer, { customer });
    } else {
      customer.createdDate = new Date();
      yield call(api.customer.createCustomer, { customer });
    }

    yield put(actions.setVisibility(false));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* listenSaveCustomer() {
  while (true) {
    yield take(actionTypes.SAVE_CUSTOMER);
    yield saveCustomer();
  }
}
