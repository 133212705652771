import { connect } from 'react-redux';
import { setFieldValue, closeDialog, saveCustomer } from '../../../store/modules/customer/actions';
import CustomerForm from './CustomerForm';

const mapStateToProps = state => ({
  customer: state.customerForm.customer,
  visible: state.customerForm.visible,
  loading: state.customerForm.loading,
  settings: state.user.settings,
});

const mapActions = {
  onChangeField: setFieldValue,
  onClose: closeDialog,
  onSave: saveCustomer,
};

export default connect(mapStateToProps, mapActions)(CustomerForm);
