import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';

import ActionsBlock from './ActionsBlock';
import FieldsBlock from './FieldsBlock';

type Props = {
  showForm: boolean,
  loading: boolean,
}

class EditForm extends Component<Props> {
  render() {
    const {
      showForm,
      loading,
    } = this.props;

    return (
      <Dialog
        open={showForm}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <FieldsBlock />
        <ActionsBlock />
        <Fade in={loading}>
          <LinearProgress />
        </Fade>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  showForm: state.edit.visibility.full,
  loading: state.edit.loading,
});

export default connect(mapStateToProps)(EditForm);
