import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_LOADING:
      return { ...state, loading: payload };

    case actionTypes.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: payload,
      };

    case actionTypes.SET_BOOKINGS:
      return { ...state, bookings: payload };

    case actionTypes.ADD_BOOKING:
      return { ...state, bookings: [...state.bookings, payload] };

    case actionTypes.UPDATE_BOOKING:
      return {
        ...state,
        bookings: state.bookings.map(booking => booking.id === payload.id
          ? payload
          : booking,
        ),
      };

    case actionTypes.DELETE_BOOKING:
      return {
        ...state,
        bookings: state.bookings.filter(booking => booking.id !== payload.id),
      };

    case actionTypes.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: payload,
      };

    case actionTypes.SET_CUSTOMER_SUGGESTIONS:
      return {
        ...state,
        customerSuggestions: payload,
      };

    case actionTypes.CLEAR_SEARCH:
      return {
        ...state,
        searchString: '',
        customerSuggestions: [],
      };

    case actionTypes.SET_BOOKING_LIST_DIRTY:
      return {
        ...state,
        isDirty: payload,
      };

    default: return state;
  }
};
