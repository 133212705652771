import * as actionTypes from './actionTypes';

export const initView = () => ({
  type: actionTypes.INIT_VIEW,
});

export const setLoading = value => ({
  type: actionTypes.SET_LOADING,
  payload: value,
});

export const setBookings = value => ({
  type: actionTypes.SET_BOOKINGS,
  payload: value,
});

export const setSelectedDate = value => ({
  type: actionTypes.SET_SELECTED_DATE,
  payload: value,
});

export const selectBooking = value => ({
  type: actionTypes.SELECT_BOOKING,
  payload: value,
});

export const createBooking = value => ({
  type: actionTypes.CREATE_BOOKING,
  payload: value,
});

export const editBooking = value => ({
  type: actionTypes.EDIT_BOOKING,
  payload: value,
});

export const moveBooking = value => ({
  type: actionTypes.MOVE_BOOKING,
  payload: value,
});

export const addBooking = value => ({
  type: actionTypes.ADD_BOOKING,
  payload: value,
});

export const updateBooking = value => ({
  type: actionTypes.UPDATE_BOOKING,
  payload: value,
});

export const deleteBooking = value => ({
  type: actionTypes.DELETE_BOOKING,
  payload: value,
});

export const loadBookings = () => ({
  type: actionTypes.LOAD_BOOKINGS,
});

export const setSearchString = value => ({
  type: actionTypes.SET_SEARCH_STRING,
  payload: value,
});

export const getCustomerSuggestions = () => ({
  type: actionTypes.GET_CUSTOMER_SUGGESTIONS,
});

export const setCustomerSuggestions = value => ({
  type: actionTypes.SET_CUSTOMER_SUGGESTIONS,
  payload: value,
});

export const selectCustomerSuggestion = value => ({
  type: actionTypes.SELECT_CUSTOMER_SUGGESTION,
  payload: value,
});

export const clearSearch = () => ({
  type: actionTypes.CLEAR_SEARCH,
});

export const createBookingsByTemplate = () => ({
  type: actionTypes.CREATE_BOOKINGS_BY_TEMPLATE
});

export const setBookingDirty = (bookingId) => ({
  type: actionTypes.SET_BOOKING_DIRTY,
  payload: bookingId,
});

export const setBookingListDirty = (value) => ({
  type: actionTypes.SET_BOOKING_LIST_DIRTY,
  payload: value,
});

export const newBookingCreated = (startTimeUtc) => ({
  type: actionTypes.NEW_BOOKING_CREATED,
  payload: startTimeUtc,
});
