import { put, takeLatest, select, call, delay } from 'redux-saga/effects';
import * as actionTypes from '../../store/modules/edit/actionTypes';
import * as actions from '../../store/modules/edit/actions';
import { showError } from '../../store/modules/app/actions';
import api from '../../services/apiService';

const getPhone = state => state.edit.currentBooking.phone;

function* getCustomerSuggestions() {
  try {
    yield delay(500);
    yield put(actions.setLoading(true));

    const phone = yield select(getPhone);
    const suggestions = yield call(api.customer.getCustomerSuggestions, { searchString: phone });

    yield put(actions.setCustomerSuggestions(suggestions));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* listenGetCustomerSuggestions() {
  yield takeLatest(actionTypes.GET_CUSTOMER_SUGGESTIONS, getCustomerSuggestions);
}
