import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SHOW_ERROR:
      return { ...state,
        toast: {
          open: true,
          message: payload,
          type: 'error',
        },
      };

    case actionTypes.SHOW_SUCCESS:
      return { ...state,
        toast: {
          open: true,
          message: payload,
          type: 'success',
        },
      };

    case actionTypes.CLOSE_TOAST:
      return { ...state,
        toast: {
          ...state.toast,
          open: false,
        },
      };

    default: return state;
  }
};
