import { all, fork } from 'redux-saga/effects';

import listenCreateCustomer from './listenCreateCustomer';
import listenSaveCustomer from './listenSaveCustomer';

export default function* customerSaga() {
  yield all([
    fork(listenCreateCustomer),
    fork(listenSaveCustomer),
  ]);
}
