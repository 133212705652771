import * as actionTypes from './actionTypes';

export const setLoading = loading => ({
  type: actionTypes.SET_LOADING,
  payload: loading,
});

export const setCurrentUser = user => ({
  type: actionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setUserSettings = settings => ({
  type: actionTypes.SET_USER_SETTINGS,
  payload: settings,
});

export const loadUserSettings = () => ({
  type: actionTypes.LOAD_USER_SETTINGS,
});

export const setFieldValue = (fieldName, value) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, value },
});

export const authorize = () => ({
  type: actionTypes.AUTHORIZE,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});
