import { take, put } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/view/actionTypes';
import { setVisibility, setCurrentBooking } from '../../store/modules/edit/actions';

export default function* listenSelectBooking() {
  while (true) {
    const { payload } = yield take(actionTypes.SELECT_BOOKING);
    yield put(setCurrentBooking(payload));
    yield put(setVisibility({
      preview: true,
      full: false,
      delete: false,
    }));
  }
}
