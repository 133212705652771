import moment from 'moment';
import { BookingStatus } from '../../../../common/models/consts/bookingStatus';
import {
  defaultItemColor,
  checkedOutItemColor,
  checkedInItemColor,
  endingSoonItemColor,
  selectedItemColor,
  confirmedItemColor,
  unconfirmedItemColor,
  fontItemColor,
  onlineItemColor,
  lockedItemColor,
} from '../../../../common/constants/colors';

// todo: refactor
export const getItemColor = (item, itemContext, minutesToNotify) => {
  if (itemContext.selected) return selectedItemColor;

  const currentDateTime = moment();

  if (item.end < currentDateTime) return checkedOutItemColor;
  if (moment(item.end).subtract(minutesToNotify, 'minutes') <= currentDateTime
    && item.end >= currentDateTime) return endingSoonItemColor;

  switch (item.status) {
    case BookingStatus.checkedIn:
      return checkedInItemColor;
  
    case BookingStatus.confirmed:
      return confirmedItemColor;

    case BookingStatus.unconfirmed:
      return unconfirmedItemColor;

    case BookingStatus.online:
      return onlineItemColor;

    case BookingStatus.locked:
      return lockedItemColor;
  }

  return defaultItemColor;
};

export const itemProps = (itemColor) => {
  return {
    style: {
      backgroundColor: itemColor,
      color: fontItemColor,
      border: 0,
      borderRadius: 0,
      marginTop: -0.5,
    },
  };
};
