// import createSagaMiddleware from 'redux-saga';

// const sagaMiddleware = createSagaMiddleware();

// export default sagaMiddleware;

import createSagaMiddleware from 'redux-saga';
// eslint-disable-next-line import/no-extraneous-dependencies
// import createSagaMonitor from '@clarketm/saga-monitor';

// configuration
// const config = {
//   level: 'log',
//   effectTrigger: true,
//   effectResolve: true,
//   actionDispatch: true,
// };

// let sagaMiddleware;

// if (process.env.NODE_ENV !== 'production') {
//   sagaMiddleware = createSagaMiddleware({
//     sagaMonitor: createSagaMonitor(config),
//   });
// } else {
//   sagaMiddleware = createSagaMiddleware();
// }

export default createSagaMiddleware();
// module.exports = sagaMiddleware;
