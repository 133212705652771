const prefix = 'edit';

export const SET_FIELD_VALUE = `${prefix}/SET_FIELD_VALUE`;
export const SET_LOADING = `${prefix}/SET_LOADING`;
export const SET_CURRENT_BOOKING = `${prefix}/SET_CURRENT_BOOKING`;
export const SET_VISIBILITY = `${prefix}/SET_VISIBILITY`;

export const SAVE_BOOKING = `${prefix}/SAVE_BOOKING`;
export const DELETE_BOOKING = `${prefix}/DELETE_BOOKING`;
export const CANCEL = `${prefix}/CANCEL`;
export const CREATE_DEFAULT = `${prefix}/CREATE_DEFAULT`;
export const CREATE_ONLINE = `${prefix}/CREATE_ONLINE`;

export const CHANGE_BOOKING_STATUS = `${prefix}/CHANGE_BOOKING_STATUS`;
export const CHECKOUT = `${prefix}/CHECKOUT`;
export const TOGGLE_ADDITIONAL = `${prefix}/TOGGLE_ADDITIONAL`;
export const TOGGLE_ONLINE = `${prefix}/TOGGLE_ONLINE`;

export const SET_CUSTOMER = `${prefix}/SET_CUSTOMER`;
export const GET_CUSTOMER_SUGGESTIONS = `${prefix}/GET_CUSTOMER_SUGGESTIONS`;
export const SET_CUSTOMER_SUGGESTIONS = `${prefix}/SET_CUSTOMER_SUGGESTIONS`;
export const SELECT_CUSTOMER_SUGGESTION = `${prefix}/SELECT_CUSTOMER_SUGGESTION`;
export const CHANGE_PHONE = `${prefix}/CHANGE_PHONE`;
export const CLEAR_PHONE = `${prefix}/CLEAR_PHONE`;
