import makeRequest from '../utils/makeRequest';
import { saveToken, getToken, removeToken } from '../utils/token';
import errorService from '../../errorService';

export const authorize = async (credentials) => {
  const token = await makeRequest({
    method: 'post',
    url: 'auth',
    body: credentials,
    errorWrapper: errorService.auth.authErr,
  });
  saveToken(token);
};

export const logout = async () => {
  removeToken();
};

export const isUserAuthorized = () => Boolean(getToken());
