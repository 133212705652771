import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './globalStyles.css';
import './socketIo'

import saga from './saga';
import store from './store';

import App from './App';
import rootSaga from './saga/rootSaga';

saga.run(rootSaga);


ReactDOM.render(
  (
    <Provider store={store}>
      <App />
    </Provider>
  ), document.getElementById('root'),
);
