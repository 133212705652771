import { put, take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/customer/actionTypes';
import { setVisibility, setCustomer } from '../../store/modules/customer/actions';
import { emptyCustomer } from '../../common/models/customer';

export default function* listenCreateCustomer() {
  while (true) {
    yield take(actionTypes.CREATE_CUSTOMER);
    yield put(setCustomer(emptyCustomer));
    yield put(setVisibility(true));
  }
}
