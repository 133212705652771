import { emptyCustomer } from '../../../common/models/customer';
import * as actionTypes from './actionTypes';
import { CustomerState, initialState } from './initialState';

export default (state: CustomerState = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        customer: {
          ...state.customer,
          [payload.fieldName]: payload.value,
        },
      };

    case actionTypes.SET_VISIBILITY:
      return {
        ...state,
        visible: payload,
      };

    case actionTypes.CLOSE_DIALOG:
      return {
        ...state,
        visible: false,
        customer: emptyCustomer,
      };

    case actionTypes.SET_CUSTOMER:
      return {
        ...state,
        customer: payload,
      };

    default: return state;
  }
};
