// @flow
import * as date from './date';
import * as value from './value';

export interface Customer {
  id: string,
  name: string,
  surname: string,
  phoneNumber: string,
  email: string,
  numberOfVisits: number,
  dateOfLastVisit: ?Date,
  comment: string,
  author: string,
  createdDate: Date,
  preferences: any[],
}

export const emptyCustomer : Customer = {
  id: '',
  name: '',
  surname: '',
  phoneNumber: '',
  email: '',
  numberOfVisits: 0,
  dateOfLastVisit: null,
  comment: '',
  author: '',
  createdDate: '',
  preferences: [],
};

export const parse = (customerRaw: any): Customer | null => customerRaw ? ({
  // eslint-disable-next-line no-underscore-dangle
  id: customerRaw._id,
  name: customerRaw.name,
  surname: customerRaw.surname,
  phoneNumber: customerRaw.phoneNumber,
  email: customerRaw.email,
  numberOfVisits: customerRaw.numberOfVisits,
  dateOfLastVisit: customerRaw.dateOfLastVisit,
  comment: customerRaw.comment,
  author: customerRaw.author,
  createdDate: date.parse(customerRaw.createdDateUtc),
  preferences: customerRaw.preferences.map(value.parse),
}) : null;

export const serialize = (customer: Customer): any => ({
  _id: customer.id,
  name: customer.name,
  surname: customer.surname,
  phoneNumber: customer.phoneNumber,
  email: customer.email,
  comment: customer.comment,
  author: customer.author,
  createdDateUtc: date.serialize(customer.createdDate),
  preferences: customer.preferences ? customer.preferences.map(value.serialize) : [],
});
