import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/edit/actionTypes';

import { saveBooking } from './utils';

export default function* listenSaveBooking() {
  while (true) {
    yield take(actionTypes.SAVE_BOOKING);
    yield saveBooking();
  }
}
