import React, { Component } from 'react';
import { connect } from 'react-redux';

import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';

import BookingList from './BookingList';
import BookingItem from './BookingItem';

import * as actions from '../../../store/modules/view/actions';
import { subscribeToBookingUpdate, subscribeToBookingCreate } from '../../../socketIo';

type Props = {
  settings: mixed,
  bookings: [mixed],
  loading: boolean,
  moveBooking: ({ startTime: Object, endTime: Object, newTableId: number }) => void,
  selectBooking: (booking: mixed) => void,
  editBooking: () => void,
  createBooking: () => void,
  setBookingDirty: (bookingId: number) => void,
  newBookingCreated: (startTimeUts: string) => void,
  selectedDate: Object,
}

class BookingsListView extends Component<Props> {
  constructor(props) {
    super(props);
    subscribeToBookingUpdate((bookingId) => {
      props.setBookingDirty(bookingId);
    });

    subscribeToBookingCreate((startTimeUtc) => {
      props.newBookingCreated(startTimeUtc);
    })
  }

  render() {
    const {
      settings,
      bookings,
      loading,
      moveBooking,
      selectBooking,
      editBooking,
      createBooking,
      setBookingDirty,
      newBookingCreated,
      selectedDate,
    } = this.props;

    return (
      <CardContent>
        <Fade in={loading}>
          <LinearProgress />
        </Fade>
        {settings !== null
          ? (
            <BookingList
              tables={settings ? settings.tables : []}
              bookings={bookings}
              selectedDate={selectedDate}
              dayRange={settings.dayRange}
              freeTableClick={createBooking}
              bookingMove={moveBooking}
              bookingClick={editBooking}
              bookingSelect={selectBooking}
              sidebarWidth={settings.sidebarWidth}
              highlightedTime={settings.highlightedTime}
              renderBooking={({ item, itemContext, getItemProps, getResizeProps }) => (
                <BookingItem
                  item={item}
                  fontColor={settings.fontColor}
                  itemContext={itemContext}
                  getItemProps={getItemProps}
                  getResizeProps={getResizeProps}
                  minutesToNotifyEnding={settings.minutesToNotifyEnding}
                />
              )}
            />
          ) : <div />}
        <div style={{ height: '50px' }} />
        {' '}
        {/* TODO: USE padding */}
      </CardContent>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.view.loading,
  bookings: state.view.bookings,
  settings: state.user.settings,
  visibleTimeStart: state.view.visibleTimeStart,
  visibleTimeEnd: state.view.visibleTimeEnd,
  selectedDate: state.view.selectedDate,
});

const mapActions = {
  moveBooking: actions.moveBooking,
  selectBooking: actions.selectBooking,
  editBooking: actions.editBooking,
  createBooking: actions.createBooking,
  setBookingDirty: actions.setBookingDirty,
  newBookingCreated: actions.newBookingCreated,
};

export default connect(mapStateToProps, mapActions)(BookingsListView);
