import * as date from './date';
import * as table from './table';
import * as value from './value';
import { BookingStatus } from './consts/bookingStatus';

export const parse = bookingRaw => bookingRaw ? ({
  id: bookingRaw._id,
  primaryTable: bookingRaw.tables && bookingRaw.tables.length
    ? bookingRaw.tables.sort((a, b) => a.orderNum - b.orderNum)[0]._id
    : null,
  name: bookingRaw.name,
  surname: bookingRaw.surname,
  email: bookingRaw.email,
  phone: bookingRaw.phone,
  numberOfGuests: bookingRaw.numberOfGuests,
  tables: bookingRaw.tables.map(table.parse),
  start: date.parse(bookingRaw.startTimeUtc),
  end: date.parse(bookingRaw.endTimeUtc),
  additional: bookingRaw.additional.map(value.parse),
  marks: bookingRaw.marks.map(value.parse),
  preferences: bookingRaw.preferences.map(value.parse),
  description: bookingRaw.description,
  canChangeGroup: !(bookingRaw.additional && bookingRaw.additional.find(i => i.customAction === 'FreezeTable')) // TODO: remove lorom parser
    && bookingRaw.tables.length <= 1,
  status: bookingRaw.status,
  author: bookingRaw.author,
  created: date.parse(bookingRaw.createdDateUtc),
  canResize: 'both',
  canMove: true,
  isOnline: bookingRaw.status === BookingStatus.online ? true : false,
  customerId: bookingRaw.customerId,
  lockedUntil: date.parse(bookingRaw.lockedUntilUtc),
  version: bookingRaw.__v,
}) : null;

export const serialize = booking => ({
  _id: booking.id,
  name: booking.name,
  surname: booking.surname,
  email: booking.email,
  phone: booking.phone,
  numberOfGuests: booking.numberOfGuests,
  tables: booking.tables ? booking.tables.map(table.serialize) : [],
  startTimeUtc: date.serialize(booking.start),
  endTimeUtc: date.serialize(booking.end),
  additional: booking.additional ? booking.additional.map(value.serialize) : [],
  marks: booking.marks ? booking.marks.map(value.serialize) : [],
  preferences: booking.preferences ? booking.preferences.map(value.serialize) : [],
  description: booking.description,
  status: booking.status,
  author: booking.author,
  createdDateUtc: date.serialize(booking.created),
  customerId: booking.customerId,
  __v: booking.version,
});

export const createEmpty = ({ tables, startTime, endTime}) => ({
  primaryTable: tables && tables.length ? tables[0].id : null,
  name: '',
  surname: '',
  email: '',
  phone: '',
  numberOfGuests: 1,
  tables,
  start: startTime,
  end: endTime,
  additional: [],
  marks: [],
  preferences: [],
  canMove: true,
  canResize: 'both',
  status: BookingStatus.unconfirmed,
  description: '',
  author: '',
  created: new Date(),
  customerId: null,
});

export const createDefault = ({ tables, startTime, endTime }) => ({
  primaryTable: tables && tables.length ? tables[0].id : null,
  name: 'С улицы', // TODO: remove hardcode
  surname: '',
  email: '',
  phone: '-----',
  numberOfGuests: 1,
  tables,
  start: startTime,
  end: endTime,
  additional: [],
  marks: [],
  preferences: [],
  canMove: true,
  canResize: 'both',
  status: BookingStatus.confirmed,
  description: '',
  author: '-----',
  created: new Date(),
});

export const createOnline = ({ tables, startTime, endTime }) => ({
  primaryTable: tables && tables.length ? tables[0].id : null,
  name: 'Через сайт', // TODO: remove hardcode
  surname: '',
  email: '',
  phone: '-----',
  numberOfGuests: 1,
  tables,
  start: startTime,
  end: endTime,
  additional: [],
  marks: [],
  preferences: [],
  canMove: true,
  canResize: 'both',
  status: BookingStatus.online,
  description: '',
  author: '-----',
  created: new Date(),
});
