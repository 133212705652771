import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import { lightGray } from '../../constants/colors';

type Props = {
    value: [mixed],
    variants: [mixed],
    placeholder: ?string,
    classes: mixed,
    onChange: ?(value: [mixed]) => void,
  }

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 250,
    },
  },
};

class ComboBox extends Component<Props> {
  constructor(props) {
    super(props);
  }

  handleChange = (event) => {
    const { onChange, variants } = this.props;

    this.setState({
        localValue: event.target.value
      },
      () => onChange(this.state.localValue.map(i => variants.find(v => v.id === i))),
    );
  }

  render() {
    const {
      variants,
      placeholder,
      classes,
      value,
    } = this.props;
    const selectedValue = value ? value.map(v => v.id) : [];
    
    return (
      <div>
        <FormControl margin="dense" variant="outlined" fullWidth>
          <Select
            multiple
            displayEmpty
            value={selectedValue}
            onChange={this.handleChange}
            input={<OutlinedInput labelWidth={0} id="select-multiple" />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <span className={classes.placeholder}>
                    { placeholder }
                  </span>
                );
              }

              return selected.map(i => variants.find(v => v.id === i).name).join(', ');
            }}>
            {variants.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

const styles = {
  placeholder: {
    color: lightGray,
  },
};

export default withStyles(styles)(ComboBox);
