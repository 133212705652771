import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/user/actionTypes';
import { logout } from './utils';

export default function* listenLogout() {
  while (true) {
    yield take(actionTypes.LOGOUT);
    yield logout();
  }
}
