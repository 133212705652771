import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { cancel, saveBooking, deleteBooking, createDefault, createOnline } from '../../../store/modules/edit/actions';
import { BookingStatus } from '../../../common/models/consts/bookingStatus';

type Props = {
  visibility: boolean,
  currentBooking: mixed,
  useOnlineBookings: boolean,
  onSave: () => void,
  onDelete: () => void,
  onCreateDefault: () => void,
  onClose: (editMode: boolean) => void,
  onCreateOnline: () => void,
}

class ActionsBlock extends Component<Props> {
  render() {
    const {
      visibility,
      useOnlineBookings,
      onSave,
      onDelete,
      onClose,
      onCreateDefault,
      currentBooking,
      onCreateOnline,
    } = this.props;

    const bookingIsLocked = currentBooking.status === BookingStatus.locked;

    // todo: move to selector
    const isBookingForToday = moment().diff(moment(currentBooking.start), 'days') === 0;

    // TODO: add confirmation
    return (
      <DialogActions>
        <Button onClick={() => onClose(visibility.delete)} variant="outlined" color="default">
            Отмена
        </Button>

        {!visibility.delete && isBookingForToday && (
          <Button onClick={onCreateDefault} variant="contained" color="default">
            С улицы
          </Button>
          )
        }

        {!visibility.delete && useOnlineBookings && (
          <Button onClick={onCreateOnline} variant="contained" color="default">
            Online
          </Button>
          )
        }

        <Button disabled={bookingIsLocked} onClick={onSave} variant="contained" color="primary">
          Сохранить
        </Button>
        
        {visibility.delete && (
          <Button disabled={bookingIsLocked} onClick={onDelete} variant="contained" color="secondary">
            Удалить
          </Button>
          )
        }
      </DialogActions>
    );
  }
}


const mapStateToProps = state => ({
  visibility: state.edit.visibility,
  currentBooking: state.edit.currentBooking,
  useOnlineBookings: state.user.settings.useOnlineBookings,
});

const mapActions = {
  onClose: cancel,
  onSave: saveBooking,
  onDelete: deleteBooking,
  onCreateDefault: createDefault,
  onCreateOnline: createOnline,
};

export default connect(mapStateToProps, mapActions)(ActionsBlock);
