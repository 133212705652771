import { fork, all } from 'redux-saga/effects';

import listenEditBooking from './listenEditBooking';
import listenLoadBookings from './listenLoadBookings';
import listenMoveBooking from './listenMoveBooking';
import listenSelectBooking from './listenSelectBooking';
import listenSetSelectedDate from './listenSetSelectedDate';
import listenGetCustomerSuggestions from './listenGetCustomerSuggestions';
import listenSelectCustomerSuggestion from './listenSelectCustomerSuggestion';
import listenCreateBooking from './listenCreateBooking';
import listenCreateBookingsByTemplate from './listenCreateBookingsByTemplate';
import listenInitView from './listenInitView';
import listenSetBookingDirty from './listenSetBookingDirty';
import listenNewBookingCreated from './listenNewBookingCreated';

export default function* viewSaga() {
  yield all([
    fork(listenEditBooking),
    fork(listenLoadBookings),
    fork(listenMoveBooking),
    fork(listenSelectBooking),
    fork(listenSetSelectedDate),
    fork(listenGetCustomerSuggestions),
    fork(listenSelectCustomerSuggestion),
    fork(listenCreateBooking),
    fork(listenInitView),
    fork(listenSetBookingDirty),
    fork(listenCreateBookingsByTemplate),
    fork(listenNewBookingCreated),
  ]);
}
