import * as table from './table';
import * as value from './value';

export const parse = settingsRaw => settingsRaw ? ({
  id: settingsRaw._id,
  dayRange: {
    start: {
      hours: settingsRaw.startOfTheDay.split(':')[0],
      minutes: settingsRaw.startOfTheDay.split(':')[1],
    },
    end: {
      hours: settingsRaw.endOfTheDay.split(':')[0],
      minutes: settingsRaw.endOfTheDay.split(':')[1],
      nextDay: settingsRaw.endOfTheDay.split(':')[2] === 'ND',
    }, // todo: move to separate parser
  },
  highlightedTime: settingsRaw.highlightedTime
    ? settingsRaw.highlightedTime.map((t) => {
      const arr = t.split(':');
      return {
        hour: parseInt(arr[0], 10),
        minute: parseInt(arr[1], 10),
      };
    })
    : [],
  bookingsInterval: settingsRaw.bookingsInterval,
  sidebarWidth: settingsRaw.sidebarWidth,
  fontColor: settingsRaw.fontColor,
  minutesToNotifyEnding: settingsRaw.minutesToNotifyEnding,
  tables: settingsRaw.tables.map(table.parse),
  addionalValues: settingsRaw.addionalValues.map(value.parse),
  marksValues: settingsRaw.marksValues.map(value.parse),
  preferences: settingsRaw.preferences.map(value.parse),
  statusMenuType: settingsRaw.statusMenuType,
  useOnlineBookings: settingsRaw.useOnlineBookings,
}) : null;

export const serialize = settings => ({
  _id: settings.d,
  startOfTheDay: settings.startOfTheDay,
  endOfTheDay: settings.endOfTheDay,
  bookingsInterval: settings.bookingsInterval,
  tables: settings.tables.map(table.serialize),
  additionalValues: settings.additionalValues.map(value.serialize),
  marksValues: settings.marksValues.map(value.serialize),
  preferences: settings.preferences.map(value.serialize),
});
