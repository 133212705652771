import { put, take, call } from 'redux-saga/effects';

import * as editActions from '../../store/modules/edit/actions';
import * as actionTypes from '../../store/modules/edit/actionTypes';

import { showError } from '../../store/modules/app/actions';

import api from '../../services/apiService';

function* selectCustomerSuggestion(payload) {
  try {
    const customer = yield call(api.customer.getCustomer, { id: payload.id });
    yield put(editActions.setCustomer(customer));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    // yield put(actions.setLoading(false));
  }
}

export default function* listenSelectCustomerSuggestion() {
  while (true) {
    const { payload } = yield take(actionTypes.SELECT_CUSTOMER_SUGGESTION);
    yield selectCustomerSuggestion(payload);
  }
}
