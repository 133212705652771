import React, { Component } from 'react';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import { InlineDatePicker, InlineTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

type Props = {
    start: number,
    end: number,
    placeholder: ?string,
    onChange: ?(value: string) => void,
    classes: mixed,
  }

class DateRangePicker extends Component<Props> {
  handleDateStartChange = (date) => {
    const {
      start,
      end,
      onChange,
    } = this.props;

    const updatedStart = moment(start).set({
      year: date.get('year'),
      month: date.get('month'),
      date: date.get('date'),
    }).valueOf();

    onChange({ start: updatedStart, end });
  };

  handleDateEndChange = (date) => {
    const {
      start,
      end,
      onChange,
    } = this.props;

    const updatedEnd = moment(end).set({
      year: date.get('year'),
      month: date.get('month'),
      date: date.get('date'),
    }).valueOf();

    onChange({ start, end: updatedEnd });
  };

  handleTimeStartChange = (date) => {
    const {
      start,
      end,
      onChange,
    } = this.props;

    const updatedStart = moment(start).set({
      hour: date.get('hour'),
      minute: Math.ceil(date.get('minute') / 5) * 5,
    }).valueOf();

    onChange({ start: updatedStart, end });
  };

  handleTimeEndChange = (date) => {
    const {
      start,
      end,
      onChange,
    } = this.props;

    const updatedEnd = moment(end).set({
      hour: date.get('hour'),
      minute: Math.ceil(date.get('minute') / 5) * 5, // todo: move to utils
    }).valueOf();

    onChange({ start, end: updatedEnd });
  };

  render() {
    const {
      start,
      end,
      placeholder,
      classes,
    } = this.props;

    const dateStart = moment(start).startOf('day');
    const dateEnd = moment(end).startOf('day');

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale="ru" moment={moment}>
        <div className={classes.timePickerContainer}>
          <InlineDatePicker
            className={classes.leftTimePicker}
            variant="outlined"
            placeholder={placeholder}
            margin="dense"
            fullWidth
            value={dateStart}
            onChange={this.handleDateStartChange}
          />
          <InlineDatePicker
            className={classes.rightTimePicker}
            variant="outlined"
            placeholder={placeholder}
            margin="dense"
            fullWidth
            value={dateEnd}
            onChange={this.handleDateEndChange}
          />
        </div>
        <div className={classes.timePickerContainer}>
          <InlineTimePicker
            className={classes.leftTimePicker}
            variant="outlined"
            ampm={false}
            value={start}
            placeholder={placeholder}
            onChange={this.handleTimeStartChange}
            margin="dense"
          />
          <InlineTimePicker
            className={classes.rightTimePicker}
            variant="outlined"
            ampm={false}
            value={end}
            placeholder={placeholder}
            onChange={this.handleTimeEndChange}
            margin="dense"
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = {
  timePickerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  leftTimePicker: {
    flex: 1,
    marginRight: '5px',
  },
  rightTimePicker: {
    flex: 1,
  },
};

export default withStyles(styles)(DateRangePicker);
