import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/view/actionTypes';
import { loadUserSettings } from '../user/utils';
import { loadBookings } from './utils';

export default function* listenInitView() {
  while (true) {
    yield take(actionTypes.INIT_VIEW);
    yield loadUserSettings();
    yield loadBookings();
  }
}
