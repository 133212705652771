export const parse = tableRaw => tableRaw ? ({
  id: tableRaw._id,
  name: tableRaw.name,
  type: tableRaw.type,
  orderNum: tableRaw.orderNum,
}) : null;

export const serialize = table => ({
  _id: table.id,
  name: table.name,
  type: table.type,
  orderNum: table.orderNum,
});
