import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/edit/actionTypes';

import { checkout } from './utils';

export default function* listenCheckout() {
  while (true) {
    yield take(actionTypes.CHECKOUT);
    yield checkout();
  }
}
