import { fork, all } from 'redux-saga/effects';

import userSaga from './user';
import viewSaga from './view';
import editSaga from './edit';
import customerSaga from './customer';

function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(viewSaga),
    fork(editSaga),
    fork(customerSaga),
  ]);
}

export default rootSaga;
