import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/view/actionTypes';

import { moveBooking } from './utils';

export default function* listenMoveBooking() {
  while (true) {
    const { payload } = yield take(actionTypes.MOVE_BOOKING);
    yield moveBooking(payload);
  }
}
