import * as actionTypes from './actionTypes';

export const showError = message => ({
  type: actionTypes.SHOW_ERROR,
  payload: message,
});

export const showSuccess = message => ({
  type: actionTypes.SHOW_SUCCESS,
  payload: message,
});

export const closeToast = () => ({
  type: actionTypes.CLOSE_TOAST,
});
