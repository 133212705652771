import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthPage from './AuthPage';
import MainPage from './MainPage';
import PrivateRoute from './PrivateRoute';

const RootRouter = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={MainPage} />
      <Route path="/auth" component={AuthPage} />
    </Switch>
  );
};


export default RootRouter;
