import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/edit/actionTypes';

import { createOnlineBooking } from './utils';

export default function* listenCreateOnline() {
  while (true) {
    yield take(actionTypes.CREATE_ONLINE);
    yield createOnlineBooking();
  }
}
