import axios from 'axios';

import { getToken } from './token';

let instance;

export default async (config) => {
  if (!instance) {
    instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      timeout: 30000,
    });
  }

  const { method = 'get' } = config;
  try {
    const response = method === 'get' || method === 'delete'
      ? await instance[method](config.url, {
        headers: { ...config.headers, 'x-auth-token': getToken() },
        params: config.params,
      })
      : await instance[method](config.url, config.body, {
        headers: { ...config.headers, 'x-auth-token': getToken() },
        params: config.params,
      });

    return response.data;
  } catch (err) {
    if (config.errorWrapper) {
      throw config.errorWrapper(err);
    } else {
      throw err;
    }
  }
};
