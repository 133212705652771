import { take, put } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/view/actionTypes';
import { setVisibility } from '../../store/modules/edit/actions';

export default function* listenEditBooking() {
  while (true) {
    yield take(actionTypes.EDIT_BOOKING);
    yield put(setVisibility({
      preview: false,
      full: true,
      delete: true,
    }));
  }
}
