import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';

import { isUserAuthorized } from '../services/apiService/modules/auth';

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        isUserAuthorized() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/auth',
            state: { from: props.location },
          }}
          />
        )
      )}
    />
  );
};
