import moment from 'moment';

export const getWorkingDayRange = (date, dayRange) => {
  return { timeStart: moment(date).set({
    hour: dayRange.start.hours,
    minute: dayRange.start.minutes,
    seconds: 0,
    milliseconds: 0,
  }).valueOf(),
  timeEnd: moment(date).set({
    hour: dayRange.end.hours,
    minute: dayRange.end.minutes,
    seconds: 0,
    milliseconds: 0,
  }).add(dayRange.end.nextDay ? 1 : 0, 'days').valueOf(),
  };
};
