// TODO: clean and refactor
import { call, put, select } from 'redux-saga/effects';

import * as userActions from '../../store/modules/user/actions';
import { showError } from '../../store/modules/app/actions';
import api from '../../services/apiService';

import { history } from '../../services/navigation';

export function* loadUserSettings() {
  try {
    yield put(userActions.setLoading(true));

    const settings = yield call(api.settings.getSettings);

    yield put(userActions.setUserSettings(settings));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(userActions.setLoading(false));
  }
}

export function* authorize() {
  try {
    yield put(userActions.setLoading(true));

    const { user: { auth: { login, password } } } = yield select();

    yield call(api.auth.authorize, {
      name: login,
      password,
    });

    yield call(history.push, '/');
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(userActions.setLoading(false));
  }
}

export function* logout() {
  try {
    yield call(api.auth.logout);
    yield call(history.push, '/auth');
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(userActions.setLoading(false));
  }
}
