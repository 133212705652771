import React, { Fragment } from 'react';
import { Router } from 'react-router-dom';

import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import { history } from './services/navigation';

import RootRouter from './pages/router';

import Toast from './common/components/Toast';

// TODO: move to separate file
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

// TODO: remove fonts
const App = () => {
  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
        <Router history={history}>
          <RootRouter />
        </Router>
        <Toast />
      </MuiThemeProvider>
    </Fragment>
  );
};

export default App;
