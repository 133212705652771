import { all, fork } from 'redux-saga/effects';

import listenLoadUserSettings from './listenLoadUserSettings';
import listenAuthorize from './listenAuthorize';
import listenLogout from './listenLogout';

export default function* viewSaga() {
  yield all([
    fork(listenLoadUserSettings),
    fork(listenAuthorize),
    fork(listenLogout),
  ]);
}
