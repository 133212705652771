import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/view/actionTypes';

import { createBooking } from './utils';

export default function* listenCreateBooking() {
  while (true) {
    const { payload } = yield take(actionTypes.CREATE_BOOKING);
    yield createBooking(payload);
  }
}
