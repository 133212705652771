import makeRequest from '../utils/makeRequest';
import errorService from '../../errorService';

import * as customerModel from '../../../common/models/customer';

const baseCustomerUrl = 'customers';

export const getCustomer = async ({ id }) => makeRequest({
  method: 'get',
  url: `${baseCustomerUrl}/${id}`,
}).then(r => customerModel.parse(r));

export const getCustomerSuggestions = async search => makeRequest({
  method: 'post',
  url: `${baseCustomerUrl}/find`,
  body: search,
  errorWrapper: errorService.customer.notFoundErr,
});

export const updateCustomer = async ({ customer }) => makeRequest({
  method: 'put',
  url: `${baseCustomerUrl}/${customer.id}`,
  body: customerModel.serialize(customer),
  errorWrapper: errorService.customer.updatingErr,
}).then(customerModel.parse);

export const createCustomer = async ({ customer }) => makeRequest({
  method: 'post',
  url: baseCustomerUrl,
  body: customerModel.serialize(customer),
  errorWrapper: errorService.customer.creatingErr,
}).then(customerModel.parse);
