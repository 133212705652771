import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        auth: {
          ...state.auth,
          [payload.fieldName]: payload.value,
        },
      };

    case actionTypes.SET_LOADING:
      return {
        ...state,
        auth: {
          ...state.auth, loading: payload,
        },
      };

    case actionTypes.SET_USER_SETTINGS:
      return { ...state, settings: payload };

    default: return state;
  }
};
