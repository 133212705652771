// @flow
import React, { Component } from 'react';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';

import TextInput from '../../../common/components/TextInput';
import ComboBox from '../../../common/components/ComboBox';
import { Customer } from '../../../common/models/customer';

const styles = {
  wrapInputsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  wrapInputLeft: {
    flex: 0.5,
    marginRight: '5px',
  },
  
  wrapInputRight: {
    flex: 0.5,
  },
};

type Props = {
  classes: any,
  settings: mixed,
  customer: Customer,
  visible: boolean,
  loading: boolean,
  onChangeField: (fieldName: string, value: string) => void,
  onClose: () => void,
  onSave: () => void,
}

class CustomerForm extends Component<Props> {
  render() {
    const {
      classes,
      settings,
      customer,
      visible,
      loading,
      onChangeField,
      onClose,
      onSave,
    } = this.props;

    return (
      <Dialog open={visible} aria-labelledby="form-dialog-title" fullWidth>
        <DialogContent>
          <div className={classes.wrapInputsContainer}>
            <TextInput
              autoFocus
              className={classes.wrapInputLeft}
              value={customer.name}
              onChange={value => onChangeField('name', value)}
              placeholder="Имя">
            </TextInput>

            <TextInput
              className={classes.wrapInputRight}
              value={customer.surname}
              onChange={value => onChangeField('surname', value)}
              placeholder="Фамилия">
            </TextInput>
          </div>

          <TextInput
            value={customer.phoneNumber}
            onChange={value => onChangeField('phoneNumber', value)}
            placeholder="Телефон"
            type="tel">
          </TextInput>

          <TextInput
            value={customer.email}
            onChange={value => onChangeField('email', value)}
            placeholder="Адрес электронной почты"
            type="email">
          </TextInput>

          {/* todo: find a proper way to render conditional fields */}
          {customer.id && (
            <div className={classes.wrapInputsContainer}>
              <TextInput
                className={classes.wrapInputLeft}
                value={customer.numberOfVisits ? customer.numberOfVisits : 'Нет данных'}
                placeholder="Количество посещений"
                disabled>
              </TextInput>

              <TextInput
                className={classes.wrapInputRight}
                value={customer.dateOfLastVisit ? moment(customer.dateOfLastVisit).format('DD.MM.YYYY') : 'Нет данных'}
                placeholder="Дата последнего посещения"
                disabled>
              </TextInput>
            </div>)
          }

          {/* todo: find a proper way to ensure that settings is not null */}
          {settings && (
            <ComboBox
              variants={settings.preferences}
              value={customer.preferences}
              onChange={value => onChangeField('preferences', value)}
              placeholder="Предпочтения">
            </ComboBox>)
          }

          <TextInput
            value={customer.comment}
            onChange={value => onChangeField('comment', value)}
            placeholder="Комментарий"
            multiline>
          </TextInput>

          <TextInput
            value={customer.author}
            onChange={value => onChangeField('author', value)}
            placeholder="Кто создал"
            disabled={customer.id && customer.author.length > 0}>
          </TextInput>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onClose()}>Закрыть</Button>
          <Button onClick={() => onSave()} color="primary">Сохранить</Button>
        </DialogActions>

        <Fade in={loading}>
          <LinearProgress></LinearProgress>
        </Fade>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CustomerForm);
