import * as booking from './modules/booking';
import * as auth from './modules/auth';
import * as settings from './modules/settings';
import * as customer from './modules/customer';

export default {
  booking,
  auth,
  settings,
  customer,
};
