import * as actionTypes from './actionTypes';

export const setFieldValue = (fieldName, value) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, value },
});

export const setLoading = value => ({
  type: actionTypes.SET_LOADING,
  payload: value,
});

export const setVisibility = value => ({
  type: actionTypes.SET_VISIBILITY,
  payload: value,
});

export const setCurrentBooking = value => ({
  type: actionTypes.SET_CURRENT_BOOKING,
  payload: value,
});

export const saveBooking = () => ({
  type: actionTypes.SAVE_BOOKING,
});

export const deleteBooking = () => ({
  type: actionTypes.DELETE_BOOKING,
});

export const cancel = editMode => ({
  type: actionTypes.CANCEL,
  payload: editMode,
});

export const createDefault = () => ({
  type: actionTypes.CREATE_DEFAULT,
});

export const createOnline = () => ({
  type: actionTypes.CREATE_ONLINE,
});

export const checkout = () => ({
  type: actionTypes.CHECKOUT,
});

export const changeBookingStatus = status => ({
  type: actionTypes.CHANGE_BOOKING_STATUS,
  payload: status,
});

export const toggleAdditional = additional => ({
  type: actionTypes.TOGGLE_ADDITIONAL,
  payload: additional,
});

export const setCustomer = value => ({
  type: actionTypes.SET_CUSTOMER,
  payload: value,
});

export const getCustomerSuggestions = () => ({
  type: actionTypes.GET_CUSTOMER_SUGGESTIONS,
});

export const setCustomerSuggestions = value => ({
  type: actionTypes.SET_CUSTOMER_SUGGESTIONS,
  payload: value,
});

export const selectCustomerSuggestion = value => ({
  type: actionTypes.SELECT_CUSTOMER_SUGGESTION,
  payload: value,
});

export const changePhone = value => ({
  type: actionTypes.CHANGE_PHONE,
  payload: value,
});

export const clearPhone = () => ({
  type: actionTypes.CLEAR_PHONE,
});

export const toggleOnline = (isOnline) => ({
  type: actionTypes.TOGGLE_ONLINE,
  payload: isOnline,
});
