import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import Autosuggest from 'react-autosuggest';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import TextInput from '../../../common/components/TextInput';
import ComboBox from '../../../common/components/ComboBox';
import DateRangePicker from '../../../common/components/DateRangePicker';
import TimeRangePicker from '../../../common/components/TimeRangePicker';
import { BookingStatus } from '../../../common/models/consts/bookingStatus';

import {
  setFieldValue,
  getCustomerSuggestions,
  selectCustomerSuggestion,
  setCustomerSuggestions,
  changePhone,
  clearPhone,
  toggleOnline
} from '../../../store/modules/edit/actions';

const autosuggestTheme = {
  container: {
    position: 'relative',
  },

  inputOpen: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  },

  suggestionsContainer: {
    display: 'none',
  },

  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: '60px',
    width: '100%',
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: '300',
    fontSize: '16px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    zIndex: '1001',
  },

  suggestionsList: {
    margin: '0',
    padding: 0,
    listStyleType: 'none',
  },

  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
    color: 'black',
  },

  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
}

const styles = (theme) => {
  return {
    wrapInputsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    wrapInputLeft: {
      flex: 0.5,
      marginRight: '5px',
    },

    wrapInputRight: {
      flex: 0.5,
    },

    wrapOnlineSwitch: {
      display: 'flex',
      justifyContent: 'center'
    },

    ...autosuggestTheme,
  }
};

type Props = {
  booking: mixed,
  settings: mixed,
  visibility: mixed,
  searchString: string,
  customerSuggestions: [],
  classes: mixed,
  onChangeField: (name: string, value: string) => void,
  onSearchChanged: (search: string) => void,
  onChangePhone: (phone: string) => void,
  onClearPhone: () => void,
  onGetCustomerSuggestions: () => void,
  onSelectCustomerSuggestion: (suggestion: Object) => void,
  onClearSuggestions: (suggestions: []) => void,
}

// TODO: split and extract
class FieldsBlock extends Component<Props> {
  renderInputComponent = inputProps => (
    <div>
      <TextField margin="dense" variant="outlined" fullWidth {...inputProps} />
    </div>
  );

  render() {
    const {
      booking,
      settings,
      customerSuggestions,
      classes,
      visibility,
      onChangeField,
      onChangePhone,
      onClearPhone,
      onGetCustomerSuggestions,
      onSelectCustomerSuggestion,
      onClearSuggestions,
      onToggleOnline
    } = this.props;

    const bookingIsLocked = booking.status === BookingStatus.locked;

    return (
      <DialogContent>
        {visibility.delete 
          && settings.useOnlineBookings
          && (booking.status === BookingStatus.online || booking.status === BookingStatus.unconfirmed)
          && (<div className={classes.wrapOnlineSwitch}>
            <FormControlLabel
              control={
                <Switch
                  checked={booking.isOnline}
                  onChange={(event) => onToggleOnline(event.target.checked)}
                  color="primary"
                />
              }
              label={booking.isOnline ? "ONLINE" : "OFFLINE"}
            />
          </div>)
        }

        <div className={classes.wrapInputsContainer}>
          <div className={classes.wrapInputLeft}>
            <Autosuggest
              focusInputOnSuggestionClick={false}
              theme={classes} // TODO: passthrough only autosuggest classes
              suggestions={customerSuggestions}
              onSuggestionsFetchRequested={onGetCustomerSuggestions}
              onSuggestionsClearRequested={() => {
                return onClearSuggestions([]);
              }}
              getSuggestionValue={suggestion => suggestion.name}
              onSuggestionSelected={(e, { suggestion }) => {
                return onSelectCustomerSuggestion(suggestion);
              }}
              renderSuggestion={suggestion => (
                <span>
                  {`${suggestion.phoneNumber} (${suggestion.name} ${suggestion.surname})`}
                </span>
              )}
              inputProps={{
                value: booking.phone,
                onChange: (e) => {
                  if (e.target.value) {
                    return onChangePhone(e.target.value);
                  }

                  return onClearPhone();
                },
                type: 'search',
                placeholder: 'Телефон',
              }}
              renderInputComponent={this.renderInputComponent}
            />
          </div>
          <div className={classes.wrapInputRight}>
            <TextInput
              value={booking.email}
              onChange={value => onChangeField('email', value)}
              placeholder="e-mail"
            />
          </div>
        </div>

        <div className={classes.wrapInputsContainer}>
          <div className={classes.wrapInputLeft}>
            <TextInput
              value={booking.name}
              onChange={value => onChangeField('name', value)}
              placeholder="Имя"
            />
          </div>
          <div className={classes.wrapInputRight}>
            <TextInput
              value={booking.surname}
              onChange={value => onChangeField('surname', value)}
              placeholder="Фамилия"
            />
          </div>
        </div>

        <ComboBox
          variants={settings.tables}
          value={booking.tables}
          onChange={value => onChangeField('tables', value)}
          placeholder="Стол"
        />

        <TextInput
          value={booking.numberOfGuests}
          onChange={value => onChangeField('numberOfGuests', value)}
          placeholder="Количество гостей"
          type="number"
        />

        {settings.dayRange.end.nextDay
          ? (
            <DateRangePicker
              start={booking.start}
              end={booking.end}
              onChange={({ start, end }) => {
                onChangeField('start', start);
                onChangeField('end', end);
              }}
            />
          )
          : (
            <TimeRangePicker
              start={booking.start}
              end={booking.end}
              onChange={({ start, end }) => {
                onChangeField('start', start);
                onChangeField('end', end);
              }}
            />
          )
        }

        <ComboBox
          variants={settings.addionalValues}
          value={booking.additional}
          onChange={value => onChangeField('additional', value)}
          placeholder="Дополнительная информация">
        </ComboBox>

        <ComboBox
          variants={settings.preferences}
          value={booking.preferences}
          onChange={value => onChangeField('preferences', value)}
          placeholder="Предпочтения">
        </ComboBox>

        <TextInput
          value={booking.description}
          onChange={value => onChangeField('description', value)}
          placeholder="Описание"
          multiline
        />

        {!visibility.delete /* TODO: create editStateProperty */
          && (
          <TextInput
            value={booking.author}
            onChange={value => onChangeField('author', value)}
            placeholder="Кто создал"
          />
          )
        }
        {visibility.delete
          && (
          <Typography align="right">
            {' '}
            {/* TODO: move to booking parser */}
            Создал:
            {' '}
            {booking.author}
            {' '}
            {moment(booking.created).format('DD.MM.YYYY HH:mm')}
          </Typography>
          )
        }
        {bookingIsLocked
          && (
          <Typography align="right">
            {' '}
            Заблокировано до:
            {' '}
            {moment(booking.lockedUntil).format('DD.MM.YYYY HH:mm')}
          </Typography>
          )
        }
      </DialogContent>
    );
  }
}

const mapStateToProps = state => ({
  booking: state.edit.currentBooking,
  customerSuggestions: state.edit.customerSuggestions,
  searchString: state.edit.searchString,
  settings: state.user.settings,
  visibility: state.edit.visibility,
});

const mapActions = {
  onChangeField: setFieldValue,
  onGetCustomerSuggestions: getCustomerSuggestions,
  onSelectCustomerSuggestion: selectCustomerSuggestion,
  onClearSuggestions: setCustomerSuggestions,
  onChangePhone: changePhone,
  onClearPhone: clearPhone,
  onToggleOnline: toggleOnline,
};

export default compose(
  connect(mapStateToProps, mapActions),
  withStyles(styles),
)(FieldsBlock);
