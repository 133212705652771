import React from 'react';

import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function numberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
}

function phoneFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="+7 (###) ###-####"
      mask="_"
    />
  );
}

const getInputProps = (type) => {
  switch (type) {
    // case 'tel':
    // return { inputComponent: phoneFormat };
    case 'number':
      return { inputComponent: numberFormat };
    default:
      return {};
  }
};

type Props = {
  value: ?string,
  placeholder: ?string,
  type: ?string,
  multiline: boolean,
  autoFocus: boolean,
  readOnly: boolean,
  disabled: boolean,
  variant: string,
  className: string,
  onChange: ?(value: string) => void,
}

const TextInput = (props: Props) => {
  const {
    value,
    placeholder,
    type = 'text',
    multiline,
    autoFocus,
    readOnly,
    disabled,
    variant,
    className,
    onChange = () => {},
  } = props;
  return (
    <TextField
      autoFocus={autoFocus}
      className={className}
      readOnly={readOnly}
      disabled={disabled}
      margin="dense"
      variant={variant || 'outlined'}
      multiline={multiline}
      placeholder={placeholder}
      value={value}
      onChange={event => onChange(event.target.value)}
      type={type === 'number' ? 'text' : type}
      fullWidth
      InputProps={getInputProps(type)}
    />
  );
};


export default TextInput;
