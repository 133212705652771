// @flow
import { Customer, emptyCustomer } from '../../../common/models/customer';

export type CustomerState = {
  +customer: Customer,
  +loading: boolean,
  +visible: boolean,
};

export const initialState : CustomerState = {
  customer: emptyCustomer,
  loading: false,
  visible: false,
};
