import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/edit/actionTypes';

import { deleteBooking } from './utils';

export default function* listenDeleteBooking() {
  while (true) {
    yield take(actionTypes.DELETE_BOOKING);
    yield deleteBooking();
  }
}
