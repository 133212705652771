import * as actionTypes from './actionTypes';

export const setFieldValue = (fieldName, value) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, value },
});

export const setLoading = value => ({
  type: actionTypes.SET_LOADING,
  payload: value,
});

export const setVisibility = value => ({
  type: actionTypes.SET_VISIBILITY,
  payload: value,
});

export const setCustomer = value => ({
  type: actionTypes.SET_CUSTOMER,
  payload: value,
});

export const saveCustomer = () => ({
  type: actionTypes.SAVE_CUSTOMER,
});

export const createCustomer = () => ({
  type: actionTypes.CREATE_CUSTOMER,
});

export const closeDialog = () => ({
  type: actionTypes.CLOSE_DIALOG,
});
