import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';

import styles from './styles';
import { getItemColor, itemProps } from './utils';

type Props = {
    item: mixed,
    timelineContext: mixed,
    itemContext: mixed,
    getItemProps: () => mixed,
    getResizeProps: () => mixed,
    classes: mixed,
    fontColor: String,
    minutesToNotifyEnding: number,
}

class BookingItem extends Component<Props> {
  render() {
    const {
      item,
      itemContext,
      getItemProps,
      getResizeProps,
      classes,
      fontColor,
      minutesToNotifyEnding,
    } = this.props;

    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const itemColor = getItemColor(item, itemContext, minutesToNotifyEnding);
    const itemAdditionalClass = item.additional.length <= 1 ? classes.tagLarge : classes.tagSmall;
    const additionalTables = item.tables.slice(1);

    return (
      <div
        {...getItemProps(itemProps(itemColor))}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div className={classes.root}>
          <div className={classes.contentContainer}>
            <span className={classes.textContainer}>
              <Typography className={classes.text} style={{ color: fontColor }}>
                {`${item.numberOfGuests} | `}
                {item.surname ? `${item.name} ${item.surname}` : item.name}
              </Typography>
              <Typography className={classes.text} style={{ color: fontColor }}>
                {item.phone}
              </Typography>
            </span>
            {item.description && <EditIcon />}
            {item.isDirty ? <WarningIcon /> : null}

            <div className={classes.badgesContainer}>
              {(item.additional ? item.additional.slice(0, 4).map(v => (
                <Avatar
                  className={itemAdditionalClass}
                  color="primary"
                >
                  {v.shortName}
                </Avatar>
              ))
                : <div />)}
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: itemColor, // todo: move to helper method
          color: itemColor,
          marginLeft: 0,
          marginRight: 0,
          marginTop: -5,
          verticalAlign: 'stretch',
          border: '1px solid #F2F2F2',
          borderTopWidth: 0,
          borderBottomWidth: 0,
          borderLeftWidth: 0,
          height: (additionalTables.length * 44) + (additionalTables.length === 0 ? 0 : 5),
        }}
        />
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  }
}

export default withStyles(styles)(BookingItem);
