import { put, takeLatest, select, call, delay } from 'redux-saga/effects';
import * as actionTypes from '../../store/modules/view/actionTypes';
import * as actions from '../../store/modules/view/actions';
import { showError } from '../../store/modules/app/actions';
import api from '../../services/apiService';

function* getCustomerSuggestions() {
  try {
    yield delay(500);
    yield put(actions.setLoading(true));

    const { view: { searchString } } = yield select();
    const suggestions = yield call(api.customer.getCustomerSuggestions, { searchString });

    yield put(actions.setCustomerSuggestions(suggestions));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* listenGetCustomerSuggestions() {
  yield takeLatest(actionTypes.GET_CUSTOMER_SUGGESTIONS, getCustomerSuggestions);
}
