import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { checkout, changeBookingStatus } from '../../../../store/modules/edit/actions';

type Props = {
  currentBooking: mixed,
  onCheckoutClick: () => void,
  onCheckinClick: () => void,
  onConfirmClick: () => void,
  onUnconfirmClick: () => void,
}

const showCheckOut = booking => booking.status === 'checkedIn'
                                && booking
                                && moment().add(-30, 'minutes') > booking.start
                                && moment() < booking.end;


class PopupView extends Component<Props> {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    const { currentBooking,
      onCheckoutClick,
      onCheckinClick,
      onConfirmClick,
      onUnconfirmClick,
    } = this.props;

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          onClick={this.handleClick}
        >
          Статус
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem disabled={moment() > currentBooking.end} selected={currentBooking.status === 'unconfirmed'} onClick={() => { this.handleClose(); onUnconfirmClick(); }}>Не подтверждено</MenuItem>
          <MenuItem disabled={moment() > currentBooking.end} selected={currentBooking.status === 'confirmed'} onClick={() => { this.handleClose(); onConfirmClick(); }}>Подтверждено</MenuItem>
          <MenuItem disabled={moment() > currentBooking.end} selected={currentBooking.status === 'checkedIn'} onClick={() => { this.handleClose(); onCheckinClick(); }}>Check-in</MenuItem>
          {showCheckOut(currentBooking) && (<MenuItem selected={moment() > currentBooking.end} onClick={() => { this.handleClose(); onCheckoutClick(); }}>Check-out</MenuItem>)}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentBooking: state.edit.currentBooking,
});

const mapActions = {
  onCheckoutClick: checkout,
  onCheckinClick: () => changeBookingStatus('checkedIn'),
  onConfirmClick: () => changeBookingStatus('confirmed'),
  onUnconfirmClick: () => changeBookingStatus('unconfirmed'),
};

export default connect(mapStateToProps, mapActions)(PopupView);
