import React, { Component } from 'react';
import moment from 'moment';

import Timeline, { TimelineMarkers, TodayMarker } from 'react-calendar-timeline';

import itemKeyConfig from '../../../common/constants/timelineKeyConfig';
import { groupHeight, itemHeightRatio } from '../../../common/constants/numbers';

import { itemMovingValidator } from './utils';
import { getWorkingDayRange } from '../../../utils/helpers/dateUtils';

const todayMarkerInterval = 60000;

type Props = {
  bookings: [mixed],
  tables: [mixed],
  timeStep: number,
  sidebarWidth: number,
  dayRange: mixed,
  selectedDate: Object,
  freeTableClick: () => void,
  bookingClick: () => void,
  bookingSelect: (booking: mixed) => void,
  bookingMove: ({ startTime: Object, endTime: Object, newTableId: number }) => void,
  renderBooking: () => void,
  highlightedTime: [mixed],
}

const groupRenderer = ({ group }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <span className="title">{group.name}</span>
    </div>
  );
};

class BookingsList extends Component<Props> {
  render() {
    const {
      bookings,
      tables,
      timeStep,
      dayRange,
      selectedDate,
      freeTableClick,
      bookingClick,
      bookingSelect,
      bookingMove,
      renderBooking,
      sidebarWidth,
      highlightedTime,
    } = this.props;

    const { timeStart, timeEnd } = getWorkingDayRange(selectedDate, dayRange);

    return (
      <Timeline
        groups={tables}
        items={bookings}
        keys={itemKeyConfig}
        timeSteps={{
          second: 1,
          minute: timeStep,
          hour: 1,
          day: 1,
          month: 1,
          year: 1,
        }}
        dragSnap={15 * 60 * 1000}// todo: remove hardcode
        sidebarWidth={sidebarWidth}
        fullUpdate
        sidebarContent={<div style={{ width: sidebarWidth }} />}
        itemsSorted
        lineHeight={groupHeight}
        itemHeightRatio={itemHeightRatio}
        stackItems={false}
        verticalLineClassNamesForTime={
          (start) => {
            const currentTime = moment(start);
            return highlightedTime.some(
              t => currentTime.hour() === t.hour && currentTime.minute() === t.minute,
            )
              ? ['highlighted-column']
              : ['default-column'];
          }
        }
        canMove
        canResize="both"
        horizontalLineClassNamesForGroup={table => table.type === 'highlighted' ? ['highlighted-row'] : ['default-row']}
        headerLabelGroupHeight={0}
        moveResizeValidator={
          (action,
            item,
            time,
            resizeEdge) => itemMovingValidator(action, item, time, resizeEdge, timeStart, timeEnd)
        }
        onCanvasClick={
          (groupId, time) => freeTableClick({ time, table: tables.find(t => t.id === groupId) })
        }
        visibleTimeStart={timeStart}
        visibleTimeEnd={timeEnd}
        onItemClick={bookingClick}
        onItemSelect={itemId => bookingSelect(bookings.find(b => b.id === itemId))}
        onItemMove={(itemId, dragTime, newGroupOrder) => {
          const booking = bookings.find(b => b.id === itemId);
          const table = tables[newGroupOrder];

          bookingMove({
            booking,
            startTime: dragTime,
            endTime: dragTime + (booking.end - booking.start),
            newTable: table,
          });
        }}
        onItemResize={(itemId, time, edge) => {
          const booking = bookings.find(b => b.id === itemId);

          bookingMove({
            booking,
            startTime: edge === 'left' ? time : booking.start,
            endTime: edge === 'left' ? booking.end : time,
            newTable: booking.tables[0],
          });
        }}
        itemRenderer={renderBooking}
        groupRenderer={groupRenderer}
      >
        <TimelineMarkers>
          <TodayMarker interval={todayMarkerInterval}>
            {({ styles }) => <div style={{ ...styles, zIndex: '1000' }} />}
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
    );
  }
}

export default BookingsList;
