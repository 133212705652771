import { groupHeight, itemHeightRatio } from '../../../../common/constants/numbers';

export default {
  root: {
    border: '1px solid #F2F2F2',
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    height: groupHeight * itemHeightRatio,
    overflow: 'hidden',
    flexBasis: '50%',
  },
  textContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: 30,
    height: 40,
  },
  text: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordWrap: 'break-word',
  },
  badgesContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    maxWidth: 48,
    minWidth: 25,
    flexBasis: '50%',
  },
  tagLarge: {
    color: 'white',
    backgroundColor: '#3F51B5',
    fontSize: 11,
    width: 22,
    height: 22,
    margin: 1,
  },
  tagSmall: {
    color: 'white',
    backgroundColor: '#3F51B5',
    fontSize: 8,
    margin: 1,
    width: 16,
    height: 16,
  },
};
