import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/view/actionTypes';

import { loadBookings } from './utils';

export default function* listenSetSelectedDate() {
  while (true) {
    yield take(actionTypes.SET_SELECTED_DATE);
    yield loadBookings();
  }
}
