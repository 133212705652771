import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import { withStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import green from '@material-ui/core/colors/green';

import { closeToast } from '../../../store/modules/app/actions';

type Props = {
    open: boolean,
    message: string,
    type: string,
    close: () => void,
    classes: mixed,
  }

class Toast extends Component<Props> {
  handleClose = (event, reason) => {
    const { close } = this.props;
    if (reason !== 'clickaway') close();
  }

  render() {
    const {
      open,
      message,
      type,
      classes,
    } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className={type === 'error' ? classes.error : classes.success}
          aria-describedby="client-snackbar"
          message={(
            <span id="client-snackbar">
              {message}
            </span>
          )}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    whiteSpace: 'pre-line',
  },
});

const mapStateToProps = ({ app: { toast } }) => ({
  open: toast.open,
  message: toast.message,
  type: toast.type,
});

const mapActions = {
  close: closeToast,
};

export default compose(
  connect(mapStateToProps, mapActions),
  withStyles(styles),
)(Toast);
