import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const doOnceOnNavAction = (handler) => {
  const unlisten = history.listen((location, action) => {
    handler(location, action);
    unlisten();
  });
};
