import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import TimerIcon from '@material-ui/icons/Timer';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';

import PopupView from './StatusBlock/PopupView';
import ButtonView from './StatusBlock/ButtonView';


import { toggleAdditional, checkout, changeBookingStatus, deleteBooking } from '../../../store/modules/edit/actions';

// todo: split to separate files and refactor

type Props = {
  open: boolean,
  loading: boolean,
  primaryItems: [mixed],
  currentBooking: mixed,
  onToggleAdditional: (additional: mixed) => void,
  onDeleteClick: () => void,
  settings: mixed,
  classes: mixed,
}

class Preview extends Component<Props> {
  render() {
    const { open,
      primaryItems,
      currentBooking,
      loading,
      onToggleAdditional,
      settings,
      onDeleteClick,
      classes,
    } = this.props;

    return (
      <Drawer open={open} variant="persistent" anchor="bottom">
        <Fade in={loading}>
          <LinearProgress />
        </Fade>
        {open && (
        <div className={classes.container}>

          <Chip
            avatar={(
              <Avatar>
                <TimerIcon />
              </Avatar>)}
            label={`${moment(currentBooking.start).format('HH:mm')}-${moment(currentBooking.end).format('HH:mm')}`}
            color="secondary"
          />

          {currentBooking
            ? primaryItems.map((item, index) => (
              <Chip
                key={index}
                label={item.name}
                color={currentBooking.additional && currentBooking.additional.find(s => s.id === item.id) ? 'primary' : 'default'}
                clickable
                onClick={() => onToggleAdditional(item)}
              />
            ))
            : <div />}

          {settings.statusMenuType === 'button' && (<ButtonView />)}
          {settings.statusMenuType === 'popup' && (<PopupView />)}

          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={onDeleteClick}
          >
            <DeleteIcon />
          </Button>
        </div>
        )}
      </Drawer>
    );
  }
}

const styles = {
  container: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  },
};

const mapStateToProps = state => ({
  settings: state.user.settings,
  open: state.edit.visibility.preview,
  loading: state.edit.loading,
  primaryItems: state.user.settings ? state.user.settings.addionalValues : [],
  currentBooking: state.edit.currentBooking,
});

const mapActions = {
  onToggleAdditional: toggleAdditional,
  onCheckoutClick: checkout,
  onDeleteClick: deleteBooking,
  onCheckinClick: () => changeBookingStatus('checkedIn'),
  onConfirmClick: () => changeBookingStatus('confirmed'),
  onUnconfirmClick: () => changeBookingStatus('unconfirmed'),
};

export default compose(
  connect(mapStateToProps, mapActions),
  withStyles(styles),
)(Preview);
