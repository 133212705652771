import { createErrorWrapper } from '../utils';

export const LOADING_ERROR = 'BOOKING.LOADING_LIST_ERROR';
export const CREATING_ERROR = 'BOOKING.CREATING_ERROR';
export const UPDATING_ERROR = 'BOOKING.UPDATING_ERROR';
export const DELETING_ERROR = 'BOOKING.DELETING_ERROR';

export const loadingErr = createErrorWrapper(LOADING_ERROR, 'Не удалось загрузить список броней');
export const creatingErr = createErrorWrapper(CREATING_ERROR, 'Не удалось создать бронь');
export const updatingErr = createErrorWrapper(UPDATING_ERROR, 'Не удалось обновить бронь');
export const deletingErr = createErrorWrapper(DELETING_ERROR, 'Не удалось удалить бронь');
