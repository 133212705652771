const prefix = 'user';

export const SET_LOADING = `${prefix}/SET_LOADING`;
export const SET_FIELD_VALUE = `${prefix}/SET_FIELD_VALUE`;
export const SET_CURRENT_USER = `${prefix}/SET_CURRENT_USER`;
export const SET_USER_SETTINGS = `${prefix}/SHOW_SUCCESS`;

export const AUTHORIZE = `${prefix}/AUTHORIZE`;
export const LOGOUT = `${prefix}/LOGOUT`;
export const LOAD_USER_SETTINGS = `${prefix}/LOAD_USER_SETTINGS`;
