import { fork, all } from 'redux-saga/effects';

import saveBooking from './listenSaveBooking';
import deleteBooking from './listenDeleteBooking';
import toggleAdditional from './listenToggleAdditional';
import checkout from './listenCheckout';
import checkin from './listenCheckin';
import createDefault from './listenCreateDefault';
import createOnline from './listenCreateOnline';
import getCustomerSuggestions from './listenGetCustomerSuggestions';
import selectCustomerSuggestion from './listenSelectCustomerSuggestion';
import clearPhone from './listenClearPhone';
import toggleOnline from './listenToggleOnline';

export default function* viewSaga() {
  yield all([
    fork(saveBooking),
    fork(deleteBooking),
    fork(toggleAdditional),
    fork(checkout),
    fork(checkin),
    fork(createDefault),
    fork(createOnline),
    fork(getCustomerSuggestions),
    fork(selectCustomerSuggestion),
    fork(clearPhone),
    fork(toggleOnline)
  ]);
}
