import moment from 'moment';

const validateMove = (item, time, visibleTimeStart, visibleTimeEnd) => {
  if (time < new Date()) return moment().valueOf();

  const length = (item.end - item.start);
  const start = time;
  const end = time + (item.end - item.start);

  if (start < visibleTimeStart) {
    return visibleTimeStart;
  }

  if (end > visibleTimeEnd) {
    return visibleTimeEnd - length;
  }

  return time;
};

const validateResize = (resizeEdge, time, visibleTimeStart, visibleTimeEnd) => {
  if (resizeEdge === 'left' && time < visibleTimeStart) return visibleTimeStart;
  if (resizeEdge === 'right' && time > visibleTimeEnd) return visibleTimeEnd;
  if (resizeEdge === 'left' && time < new Date()) return new Date();

  return time;
};

export const itemMovingValidator = (action, item, time, resizeEdge, visibleTimeStart, visibleTimeEnd) => action === 'move'
  ? validateMove(item, time, visibleTimeStart, visibleTimeEnd)
  : validateResize(resizeEdge, time, visibleTimeStart, visibleTimeEnd);
