import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { without } from 'ramda';
// TODO: clean and refactor

import api from '../../services/apiService';
import * as viewActions from '../../store/modules/view/actions';
import * as editActions from '../../store/modules/edit/actions';
import { showError } from '../../store/modules/app/actions';
import { createDefault, createEmpty, createOnline } from '../../common/models/booking';
import { BookingStatus } from '../../common/models/consts/bookingStatus';

const getCurrentBooking = state => state.edit.currentBooking;

function* updateCurrentBooking() {
  try {
    yield put(editActions.setLoading(true));

    const currentBooking = yield select(getCurrentBooking);
    const result = yield call(api.booking.updateBooking, { booking: currentBooking });
    yield put(viewActions.updateBooking(result));
    yield put(editActions.setCurrentBooking(result));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(editActions.setLoading(false));
  }
}

export function* saveBooking() {
  try {
    yield put(editActions.setLoading(true));
    
    const currentBooking = yield select(getCurrentBooking);
    if (currentBooking.id) {
      yield updateCurrentBooking();
    } else {
      const result = yield call(api.booking.createBooking, { booking: currentBooking });
      yield put(viewActions.addBooking(result));
    }

    yield put(editActions.setVisibility({
      full: false,
      preview: false,
      delete: false,
    }));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(editActions.setLoading(false));
  }
}

export function* deleteBooking() {
  try {
    yield put(editActions.setLoading(true));

    const currentBooking = yield select(getCurrentBooking);
    yield call(api.booking.deleteBooking, { bookingId: currentBooking.id });
    yield put(viewActions.deleteBooking(currentBooking));
    yield put(editActions.setVisibility({
      full: false,
      preview: false,
      delete: false,
    }));
  } catch (err) {
    yield put(showError(err.message));
  } finally {
    yield put(editActions.setLoading(false));
  }
}

export function* toggleAdditional(toggledAdditional) {
  const { edit: { currentBooking: { additional } } } = yield select();

  const selected = additional.find(s => s.id === toggledAdditional.id);
  if (selected) {
    const updatedAdditional = without([selected], additional);
    yield put(editActions.setFieldValue('additional', updatedAdditional));
  } else {
    additional.push(toggledAdditional);
    yield put(editActions.setFieldValue('additional', additional));
  }

  yield updateCurrentBooking();
}

export function* checkout() {
  yield put(editActions.setFieldValue('end', moment()));
  yield updateCurrentBooking();
}

export function* changeBookingStatus(status) {
  yield put(editActions.setFieldValue('status', status));
  yield updateCurrentBooking();
}

export function* createDefaultBooking() {
  const currentBooking = yield select(getCurrentBooking);
  yield put(editActions.setCurrentBooking(createDefault({
    tables: currentBooking.tables,
    startTime: currentBooking.start,
    endTime: currentBooking.end,
  })));

  yield saveBooking();
}

export function* createOnlineBooking() {
  const currentBooking = yield select(getCurrentBooking);
  yield put(editActions.setCurrentBooking(createOnline({
    tables: currentBooking.tables,
    startTime: currentBooking.start,
    endTime: currentBooking.end,
  })));

  yield saveBooking();
}

export function* toggleOnline(isOnline) {
  const currentBooking = yield select(getCurrentBooking);
  if (isOnline) {
    const onlineBooking = createOnline({
      tables: currentBooking.tables,
      startTime: currentBooking.start,
      endTime: currentBooking.end
    });
    yield put(editActions.setCurrentBooking({
      ...onlineBooking,
      id: currentBooking.id,
      isOnline,
      version: currentBooking.version,
    }));
  } else {
    yield put(editActions.setCurrentBooking({
      ...currentBooking,
      id: currentBooking.id,
      isOnline,
      status: BookingStatus.unconfirmed,
      author: currentBooking.author,
      version: currentBooking.version,
    }));
  }
}
