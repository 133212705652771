import { take } from 'redux-saga/effects';

import * as actionTypes from '../../store/modules/edit/actionTypes';
import { toggleOnline } from './utils';

export default function* listenToggleAdditional() {
  while (true) {
    const { payload } = yield take(actionTypes.TOGGLE_ONLINE);
    yield toggleOnline(payload);
  }
}
