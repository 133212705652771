import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import { checkout, changeBookingStatus } from '../../../../store/modules/edit/actions';

type Props = {
  currentBooking: mixed,
  onCheckoutClick: () => void,
  onCheckinClick: () => void,
  onConfirmClick: () => void,
}

const showCheckIn = booking => booking.status !== 'checkedIn'
                                && booking
                                && moment() > booking.start
                                && moment() < booking.end;

const showCheckOut = booking => booking.status === 'checkedIn'
                                && booking
                                && moment().add(-30, 'minutes') > booking.start
                                && moment() < booking.end;


const showConfirm = booking => booking.status === 'unconfirmed'
                                && booking
                                && moment() < booking.start;

class ButtonView extends Component<Props> {
  render() {
    const { currentBooking,
      onCheckoutClick,
      onCheckinClick,
      onConfirmClick,
    } = this.props;

    return (
      <div>
        {showConfirm(currentBooking) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={onConfirmClick}
        >
              Подтвердить
        </Button>
        )}

        {showCheckIn(currentBooking) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={onCheckinClick}
        >
              Check-in
        </Button>
        )}

        {showCheckOut(currentBooking) && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onCheckoutClick}
          >
            Check-out
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentBooking: state.edit.currentBooking,
});

const mapActions = {
  onCheckoutClick: checkout,
  onCheckinClick: () => changeBookingStatus('checkedIn'),
  onConfirmClick: () => changeBookingStatus('confirmed'),
};

export default connect(mapStateToProps, mapActions)(ButtonView);
